.menu_yama {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 200px;
  z-index: 10;

  @media (max-width: 1280px) {
    display: none;
  }

  .menu {
    width: 100%;
    max-width: 1550px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    a {
      padding: 10px 30px;
      text-decoration: none;
      margin-top: 10px;

      &:last-child {
        margin-left: 150px;

        @media (max-width: 1920px) {
          margin-left: 100px;
        }
      }
      &:first-child {
        margin-right: 150px;

        @media (max-width: 1920px) {
          margin-right: 100px;
        }
      }

      .link {
        position: relative;
        opacity: 0.4;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        p {
          @include TypoLexSemi();
          font-size: 18px;
          color: #ffffff;
          margin-bottom: 8px;

          @media (max-width: 1920px) {
            font-size: 16px;
          }
        }
        .line {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          transform: translateX(-105%);
          transition: all 0.3s ease-in-out;
        }
      }
      
      &:hover {
        .link {
          opacity: 1;

          .line {
            transform: translateX(0);
          }
        }
      }
    }

    .logo {
      width: 185px;
      margin: 0 150px;

      @media (max-width: 1920px) {
        margin: 0 100px;
      }
    }
  }
}