html,
body {
  width: 100%;
  font-size: 0;
}
.hp {
  width: 100%;

  .header {
    width: 100%;
    height: 100vh;
    min-height: 690px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    position: relative;
    z-index: 2;

    @media (max-width: 1920px) {
      min-height: 550px;
    }
    @media (max-width: 1280px) {
      min-height: 450px;
    }

    .container_lottie {
      width: 1560px;
      height: 260px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1920px) {
        width: 1160px;
        height: 190px;
      }
      @media (max-width: 1280px) {
        width: 720px;
        height: 130px;
      }
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        overflow: hidden;
      }

      .montagne {
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
          width: 120%;
        }

        #lottie {
          background-color:#000000;
          width: 100%;
          height: 100%;
          display:inline-block;
          overflow: hidden;
          transform: translate3d(0,0,0);
          text-align: center;
          opacity: 1;
        }
      }
      
      .text {
        position: absolute;
        bottom: 0;

        @media (max-width: 1280px) {
          bottom: -10px;
        }
        @media (max-width: 768px) {
          position: relative;
          bottom: 0;
          padding: 0 20px;
        }
        
        p {
          font-family: 'josefin-sans', sans-serif;
          font-weight: 600;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          line-height: 150%;
          text-transform: uppercase;
          letter-spacing: 10px;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
        
      }
    }
    .scroll {
      position: absolute;
      bottom: 40px;
      left: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      p {
        @include TypoLexSemi();
        font-size: 10px;
        color: #ffffff;
        margin-bottom: 15px;
        text-transform: uppercase;
      }
      .fleche {
        width: 16px;
        animation: animFleche 1.3s infinite ease-in-out alternate;
      }
      @keyframes animFleche {
        from {
          transform: translateY(0px);
        }
        to {
          transform: translateY(30px);
        }
      }
    }
  }
  .blocs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .triangle {
      width: 100%;
      position: absolute;
      top: -2px;
      left: 0;

      @media (max-width: 1280px) {
        position: relative;
      }
    }

    .line {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1280px) {
        flex-direction: column;

        &:last-child {
          flex-direction: column-reverse;
        }
      }

      .text {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #ffffff;

        @media (max-width: 1280px) {
          width: 100%;
          padding: 60px 60px;
        }
        @media (max-width: 768px) {
          padding: 30px 15px;
        }

        .title {
          width: 100%;
          max-width: 600px;

          @media (max-width: 1920px) {
            max-width: 520px;
          }
          @media (max-width: 1280px) {
            max-width: none;
          }

          h2 {
            @include TypoLexSemi();
            font-size: 50px;
            color: #000000;
            margin-bottom: 40px;
            max-width: 550px;
            width: 100%;
  
            @media (max-width: 1920px) {
              font-size: 40px;
              margin-bottom: 30px;
              max-width: 500px;
            }
            @media (max-width: 1280px) {
              max-width: none;
            }
            @media (max-width: 768px) {
              font-size: 32px;
              margin-bottom: 20px;
            }
          }
        }
        
        p {
          @include TypoLexLi();
          font-size: 20px;
          color: #000000;
          margin-bottom: 25px;
          max-width: 600px;
          width: 100%;
          line-height: 140%;

          @media (max-width: 1920px) {
            font-size: 16px;
            margin-bottom: 20px;
            max-width: 520px;
          }
          @media (max-width: 1280px) {
            max-width: none;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
        .image_text {
          margin-top: 50px;
          align-self: end;
          width: calc(50% + 300px);

          @media (max-width: 1280px) {
            width: 60%;
            position: relative;
            left: 60px;
          }
          @media (max-width: 768px) {
            width: 90%;
            left: 15px;
            margin-top: 0;
          }
        }
      }
      .image {
        width: 50%;

        @media (max-width: 1280px) {
          width: 100%;
        }
      }

      &:last-child {
        background-color: #000000;

        .text {
          background-color: #000000;

          p {
            color: #ffffff;
          }
        }
      }
    }
  }
  .yama {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 3px solid #000000;
    border-bottom: 3px solid #000000;

    @media (max-width: 768px) {
      border-bottom: none;
    }

    .left {
      width: calc(100% - 180px);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 1280px) {
        width: calc(100% - 117px);
      }
      @media (max-width: 768px) {
        width: calc(100% - 55px);
        padding: 40px 15px;
      }

      p {
        @include TypoLexLi();
        font-size: 58px;
        color: #000000;
        text-align: center;
        line-height: 140%;
        width: 100%;
        max-width: 920px;

        @media (max-width: 1920px) {
          font-size: 40px;
          max-width: 820px;
        }
        @media (max-width: 1280px) {
          font-size: 36px;
          max-width: 530px;
        }
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
    }
    .right {
      width: 180px;
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      border-left: 3px solid #000000;
      height: 655px;

      @media (max-width: 1920px) {
        width: 150px;
        height: 375px;
      }
      @media (max-width: 1280px) {
        width: 117px;
      }
      @media (max-width: 768px) {
        width: 55px;
        height: 310px;
      }

      .image {
        margin: 0 auto;
        width: 35px;

        @media (max-width: 1920px) {
          width: 25px;
        }
        @media (max-width: 768px) {
          width: 18px;
        }
      }
    }
  }
  .contact {
    width: 100%;
    height: 1120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 3px solid #000000;

    @media (max-width: 1920px) {
      height: 650px;
    }
    @media (max-width: 1280px) {
      border: none;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }

    .image {
      height: 100%;
      width: 25%;
      background-image: url("/images/home/contactez-nous.webp");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: 1280px) {
        width: 120px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .image_mob {
      display: none;
      width: 100%;

      @media (max-width: 768px) {
        display: block;
      }
    }

    .contact_text {
      width: 10%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 3px solid #000000;
      border-right: 3px solid #000000;

      @media (max-width: 1280px) {
        display: none;
      }

      .text {
        transform: rotate(-90deg);
        width: 500px;

        h2 {
          @include TypoLexSemi();
          font-size: 50px;
          color: #000000;
          text-align: center;
          text-transform: uppercase;

          @media (max-width: 1920px) {
            font-size: 40px;
          }
        }
      }
    }
    .bloc {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 1280px) {
        align-items: flex-start;
        width: calc(100% - 120px);
      }
      @media (max-width: 768px) {
        width: 100%;
        height: auto;
        align-items: center;
      }

      .contactez-nous {
        display: none;
        padding-left: 30px;
        margin-top: 26px;
        margin-bottom: 35px;

        @media (max-width: 1280px) {
          display: block;
        }
        @media (max-width: 768px) {
          padding-left: 0;
          padding: 0 30px;
          margin-top: 25px;
          margin-bottom: 25px;
        }

        .text {
          h2 {
            @include TypoLexSemi();
            font-size: 40px;
            color: #000000;
            text-align: center;
            text-transform: uppercase;

            @media (max-width: 768px) {
              font-size: 32px;
            }
          }
        }
      }

      .infos {
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-left: 120px;
        border-bottom: 3px solid #000000;

        @media (max-width: 1920px) {
          height: 150px;
          padding-left: 60px;
        }
        @media (max-width: 1280px) {
          height: auto;
          padding-left: 30px;
          padding-bottom: 30px;
        }
        @media (max-width: 768px) {
          flex-direction: column;
        }

        .address {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          @media (max-width: 768px) {
            align-items: center;
            width: 100%;
            padding: 0 30px;
          }

          &:first-child {
            margin-right: 200px;

            @media (max-width: 1920px) {
              margin-right: 60px;
            }
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 25px;
            }
          }

          .top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 3px;

            @media (max-width: 768px) {
              margin-bottom: 5px;
            }

            p {
              @include TypoLexLi();
              color: #000000;
              font-size: 28px;
              margin-right: 10px;

              @media (max-width: 1920px) {
                font-size: 24px;
              }
              @media (max-width: 1280px) {
                font-size: 20px;
              }
            }
            .img {
              height: 32px;
              overflow: visible;

              @media (max-width: 1920px) {
                height: 24px;
              }
              @media (max-width: 1280px) {
                height: 18px;
              }

              img {
                height: 100%;
                width: auto;
              }
            }
          }
          .bot {
            p, a {
              @include TypoLexLi();
              font-size: 28px;
              text-transform: uppercase;
              color: #999999;
              text-decoration: none;

              @media (max-width: 1920px) {
                font-size: 24px;
              }
              @media (max-width: 1280px) {
                font-size: 20px;
              }
              @media (max-width: 768px) {
                text-align: center;
              }
            }
          }
        }
      }
      .cont {
        width: 100%;
        padding: 0 120px;
        height: calc(100% - 220px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (max-width: 1920px) {
          height: calc(100% - 150px);
          padding: 0 60px;
        }
        @media (max-width: 1280px) {
          height: calc(100% - 193px);
          padding: 0 30px;
        }
        @media (max-width: 768px) {
          padding: 35px 15px;
        }
      }
    }
  }
}
.vision {
  width: 100%;
  background-color: #000000;

  .header {
    width: 100%;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 300px;
    padding-bottom: 90px;

    @media (max-width: 1280px) {
      padding: 0 60px 60px 60px;
      padding-top: 300px;
      margin: 0 auto;
      max-width: 900px;
    }
    @media (max-width: 768px) {
      padding: 0 15px 40px 15px;
      padding-top: 200px;
      margin: 0 auto;
    }

    h1 {
      @include TypoLexSemi();
      font-size: 50px;
      color: #ffffff;
      text-align: center;

      @media (max-width: 1280px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    p {
      @include TypoLexLi();
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      line-height: 120%;
      max-width: 1080px;
      margin-top: 80px;

      @media (max-width: 1280px) {
        font-size: 20px;
        margin-top: 24px;
      }
    }
  }
  .blocs {
    width: 100%;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 120px 90px 120px;
    margin: 0 auto;

    @media (max-width: 1920px) {
      padding: 0 60px 90px 60px;
    }
    @media (max-width: 1280px) {
      max-width: 900px;
    }
    @media (max-width: 768px) {
      padding: 0 0 90px 0;
    }

    .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 60px;

      @media (max-width: 1280px) {
        flex-direction: column-reverse;

        &:nth-child(2n) {
          flex-direction: column;
        }
      }
      @media (max-width: 1280px) {
        margin-bottom: 0;
      }
      @media (max-width: 768px) {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .image {
        width: 50%;

        @media (max-width: 1280px) {
          width: 100%;
          margin-bottom: 30px;
        }
        @media (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .text {
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 60px;

        @media (max-width: 1920px) {
          margin-left: 0;
          padding-right: 60px;
        }
        @media (max-width: 1280px) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 60px;
          padding-right: 0;
        }
        @media (max-width: 768px) {
          padding: 0 15px;
          margin-bottom: 40px;
        }

        &:nth-child(2n) {
          margin-left: 150px;

          @media (max-width: 1920px) {
            margin-left: 60px;
            padding-right: 0;
          }
          @media (max-width: 1280px) {
            margin-left: 0;
          }
          @media (max-width: 768px) {
            padding-right: 15px;
          }
        }

        .title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 20px;

          .img {
            width: 40px;
            margin-right: 20px;
          }

          h3 {
            @include TypoLexSemi();
            font-size: 28px;
            color: #ffffff;
            line-height: 120%;
            max-width: 450px;
          }
        }
        p {
          @include TypoLexLi();
          font-size: 20px;
          color: #ffffff;
          line-height: 130%;
          max-width: 600px;

          @media (max-width: 1920px) {
            font-size: 16px;
          }
          @media (max-width: 1280px) {
            width: 100%;
            max-width: none;
          }
        }
      }
      .no-mb {
        margin-bottom: 0;
      }
    }
  }
}
.equipe {
  width: 100%;
  background-color: #841820;
  padding-top: 300px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1280px) {
    padding: 200px 60px 95px 60px;
  }
  @media (max-width: 768px) {
    padding: 0;
    padding-top: 90px;
    padding-bottom: 65px;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1320px;
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 1920px) {
      max-width: 1160px;
    }
    @media (max-width: 1280px) {
      flex-direction: column;
      max-width: none;
      margin-bottom: 95px;

      &:nth-child(2n) {
        flex-direction: column-reverse;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media (max-width: 768px) {
      margin-bottom: 60px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .image {
      width: 425px;

      @media (max-width: 1920px) {
        width: 350px;
      }
      @media (max-width: 1280px) {
        width: 100%;
      }
    }

    .text {
      width: 835px;

      @media (max-width: 1920px) {
        width: 750px;
      }
      @media (max-width: 1280px) {
        width: 100%;
        margin-top: 30px;
      }
      @media (max-width: 768px) {
        padding: 0 15px;
        margin-top: 20px;
      }

      h3 {
        @include TypoLexSemi();
        font-size: 24px;
        color: #ffffff;
        line-height: 140%;
        text-transform: uppercase;
      }
      h4 {
        @include TypoCorSemiIt();
        font-size: 24px;
        color: #ffffff;
      }
      p {
        @include TypoLexLi();
        font-size: 20px;
        color: #ffffff;
        margin-top: 30px;
        line-height: 130%;

        @media (max-width: 1920px) {
          font-size: 16px;
        }
      }
    }
  }
}
.residences {
  width: 100%;
  background-color: #000000;
  padding-top: 250px;

  @media (max-width: 1920px) {
    padding-top: 220px;
  }

  .pers {
    width: 100%;
    display: inline-block;
    position: relative;
    min-height: 1040px;

    @media (max-width: 1920px) {
      min-height: 850px;
    }

    @media (max-width: 1280px) {
      display: none;
    }

    .visu {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      //opacity: 0;
    }

    #visu1 {
      opacity: 0;
      //background-image: url("/images/residences/clamart2.webp");
    }
    #visu2 {
      opacity: 0;
      //background-image: url("/images/residences/clamart-nonFlou.webp");
    }
    #visu3 {
      opacity: 0;
      //background-image: url("/images/residences/chenne-nonFlou.webp");
    }
    #visu4 {
      opacity: 0;
      //background-image: url("/images/residences/nointel-nonFlou.webp");
    }
    #visu5 {
      opacity: 0;
      background-image: url("/images/residences/villejuif01.webp");
    }
    #visu6 {
      opacity: 0;
    }
    #visu7 {
      opacity: 0;
      background-image: url("/images/residences/lechesnay01.webp");
    }
    #visu8 {
      opacity: 0;
    }

    .text {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 99;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 1000px;
      height: 100%;
      padding-top: 70px;
      padding-left: 180px;

      @media (max-width: 1920px) {
        //width: 650px;
        padding-top: 55px;
        padding-left: 120px;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0), #000 32%);
        opacity: 0.68;
      }

      .controlers {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: default;	

        .item {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 5px;
          width: 670px;
          //padding: 10px 0;
          padding: 0;

          &:last-child {
            margin-bottom: 0;
          }

          .chiffre {
            padding: 5px;
            margin-right: 15px;
            background-color: transparent;

            p {
              @include TypoLexLi();
              font-size: 40px;
              color: #ffffff;

              @media (max-width: 1920px) {
                font-size: 32px;
              }
            }
          }
          .city {
            p {
              @include TypoLexSemi();
              font-size: 40px;
              color: #ffffff;
              text-transform: uppercase;

              @media (max-width: 1920px) {
                font-size: 32px;
              }
            }
          }
        }
      }
      .line {
        margin: 60px 0;
        width: 420px;
        height: 1px;
        background-color: #ffffff;

        @media (max-width: 1920px) {
          margin: 35px 0;
        }
      }
      .description {
        position: relative;
        width: 100%;
        max-width: 820px;

        .text_descr {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 40px;

            h4 {
              @include TypoLexSemi();
              font-size: 20px;
              color: #ffffff;
              margin-bottom: 10px;
              text-transform: uppercase;
              line-height: 120%;

              span {
                font-size: 28px;
                padding-bottom: 17px;
                display: block;
              }

              @media (max-width: 1920px) {
                font-size: 16px;
              }
            }
            h3 {
              @include TypoLexSemi();
              font-size: 20px;
              color: #ffffff;
              line-height: 120%;

              @media (max-width: 1920px) {
                font-size: 16px;
              }

              sup {
                @include TypoLexSemi();
                font-size: 12px;
                color: #ffffff;

                @media (max-width: 1920px) {
                  font-size: 10px;
                }
              }
            }
            p {
              @include TypoLexLi();
              font-size: 16px;
              color: #ffffff;
              margin-top: 10px;
              line-height: 140%;

              @media (max-width: 1920px) {
                font-size: 14px;
              }
            }
          }

          .bot {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            p {
              @include TypoLexLi();
              font-size: 20px;
              color: #ffffff;
              line-height: 120%;
              margin-bottom: 10px;

              @media (max-width: 1920px) {
                font-size: 16px;
              }

              span {
                font-weight: 600;
              }
            }
          }
        }

        #descr1,
        #descr2,
        #descr3,
        #descr4,
        #descr5,
        #descr6,
        #descr7,
        #descr8 {
          opacity: 0;
        }
      }
    }
  }
}

.residences2 {

  width: 100%;
  background-color: #000000;
  padding-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
  min-height: 1120px;
  overflow: hidden;

  @media (max-width: 1439px) {
    min-height: 1260px;
  }

  
  .res-nav {
    width: 35%;
    height: 100%;
    background-color: black;

    @media (max-width: 1279px) {
      display: none;
    }

    .text {

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      // padding-top: 70px;
      // padding-left: 180px;
      padding: 60px;


      .controlers {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: default;
        width: 100%;

        .item {
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 5px;
         
          //padding: 10px 0;
          padding: 0;

          &:last-child {
            margin-bottom: 0;
          }

          .chiffre {
            padding: 5px;
            margin-right: 15px;
            background-color: transparent;
            //transform: translateY(-5px);

            p {
              @include TypoLexLi();
              font-size: 40px;
              color: #ffffff;

              @media (max-width: 2400px) {
                font-size: 32px;
              }

              @media (max-width: 1919px) {
                font-size: 24px;
              }

            }
          }
          .city {
            p {
              @include TypoLexSemi();
              font-size: 40px;
              color: #ffffff;
              text-transform: uppercase;

              @media (max-width: 2400px) {
                font-size: 32px;
              }

              @media (max-width: 1919px) {
                font-size: 24px;
              }

            }
          }
        }
      }
      .line {
        margin: 60px 0;
        width: 100%;
        height: 1px;
        background-color: #ffffff;

        @media (max-width: 1920px) {
          margin: 35px 0;
        }
      }
      .description {
        position: relative;
        width: 100%;

        .text_descr {

          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;

          .top {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-bottom: 40px;

            h4 {
              @include TypoLexSemi();
              font-size: 20px;
              color: #ffffff;
              margin-bottom: 10px;
              text-transform: uppercase;
              line-height: 120%;

              span {
                font-size: 28px;
                padding-bottom: 17px;
                display: block;

                @media (max-width: 1919px) {
                  font-size: 24px;
                }

              }

              @media (max-width: 1920px) {
                font-size: 16px;
              }
            }
            h3 {
              @include TypoLexSemi();
              font-size: 20px;
              color: #ffffff;
              line-height: 120%;

              @media (max-width: 1920px) {
                font-size: 16px;
              }

              sup {
                @include TypoLexSemi();
                font-size: 12px;
                color: #ffffff;

                @media (max-width: 1920px) {
                  font-size: 10px;
                }
              }
            }
            p {
              @include TypoLexLi();
              font-size: 16px;
              color: #ffffff;
              margin-top: 10px;
              line-height: 140%;

              @media (max-width: 1920px) {
                font-size: 14px;
              }
            }
          }

          .bot {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            p {
              @include TypoLexLi();
              font-size: 20px;
              color: #ffffff;
              line-height: 120%;
              margin-bottom: 10px;

              @media (max-width: 1920px) {
                font-size: 16px;
              }

              span {
                font-weight: 600;
              }
            }
          }
        }

        #descr1,
        #descr2,
        #descr3,
        #descr4,
        #descr5,
        #descr6,
        #descr7,
        #descr8 {
          opacity: 0;
        }
      }
    }

  }

  .res-pers {

    width: 65%;
    height: 100%;
    background-color: grey;
    position: relative;

    .visu {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
    }

    #visu1 {
      opacity: 0;
    }
    #visu2 {
      opacity: 0;
    }
    #visu3 {
      opacity: 0;
    }
    #visu4 {
      opacity: 0;
    }
    #visu5 {
      opacity: 0;
      background-image: url("/images/residences/villejuif01.webp");
    }
    #visu6 {
      opacity: 0;
    }
    #visu7 {
      opacity: 0;
      background-image: url("/images/residences/lechesnay01.webp");
    }
    #visu8 {
      opacity: 0;
      background-image: url("/images/residences/psg01.webp");
    }

  }

}

img {
  width: 100%;
  height: auto;
}
label.error {
  position: absolute;
  bottom: -14px;
  z-index: 99;
  font-family: "Lexend", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: red;
  font-size: 12px;
  padding-top: 10px;
}


.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.sliderNav {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  z-index: 5 !important;
  height: 55px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row-reverse !important;
  pointer-events: none !important;
}

.sliderNavBtn {
  background-color: #841820 !important;
  width: 55px !important;
  height: 55px !important;
  margin: 0 !important;
  position: relative !important;
  padding: 0 !important;
  right: 0 !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  pointer-events: all !important;

  &::after {
    color: white !important;
    font-size: 20px !important;
    font-weight: 700 !important;
  }
}

.sliderNav .swiper-button-prev {
  display: flex;
}

.swiper-pagination-bullet-active {
  background-color: #fff !important;
}