// BLOC FORMULAIRE

.form {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
  flex-flow: wrap;
  background-color: #ffffff;

  
  .group_bot {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .form-inner {
    @include TypoOutBold();
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    z-index: 9;

    .group_form {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 30px;

      @media (max-width: 1920px) {
        margin-top: 5px;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }

      &:first-child {
        margin-top: 0;
      }

      .form-item {
        width: 320px;

        @media (max-width: 1920px) {
          width: 220px;
        }
        @media (max-width: 1280px) {
          width: 185px;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
      }

      textarea {
        width: 100%;
        max-width: 100%;
        @include TypoLexLi();
        font-size: 20px;
        width: 100%;
        display: block;
        background-color: #ffffff !important;
        border: none;
        height: 58px;
        color: #000000 !important;
        padding: 0;
        padding-top: 15px;
        margin-top: 15px;
        border-bottom: 2px solid #d8d8d8;
        max-height: 200px;

        @media (max-width: 1920px) {
          font-size: 16px;
          max-height: 130px;
        }

        &::placeholder {
          color: #000000;
        }
      }
    }
    .no-mt {
      margin-top: 0;
    }
    ////////////
    // CHAMPS //
    ////////////

    .container-form {
      width: auto;
      max-width: 1020px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 !important;

      .form-item {
        position: relative;
        @include FullCenter();

        @media (max-width: 768px) {
          width: 100%;
        }

        & + .form-item {
          margin-top: 10px;
          @include FullCenter();
          flex-direction: column;
        }

        .easy-autocomplete {
          width: 100% !important;

          & > input {
            min-width: 100% !important;
            box-shadow: 0 0 black !important;
          }
        }

        a {
          color: $colTypoForm;
          text-decoration: underline;
        }

        ////////////
        // INPUTS //
        ////////////

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="number"] {
          @include TypoLexLi();
          font-size: 20px;
          width: 100%;
          display: block;
          background-color: #ffffff !important;
          border: none;
          height: 58px;
          color: #000000 !important;
          padding: 0;
          border-bottom: 2px solid #d8d8d8;

          @media (max-width: 1920px) {
            font-size: 16px;
          }

          &::placeholder {
            color: #000000;
          }

          /*&:hover {
            opacity: 1;
            cursor: pointer;
          }

          &:valid,
          &:focus {
            opacity: 1;
            border-bottom: 1px solid #202e44;
          }*/
        }
        ///////////
        // RADIO //
        ///////////

        .left {
          margin-right: 10px;
        }        

        ////////////////
        // VALIDATION //
        ////////////////

        .error {
          color: red;
          font-size: 12px;
          padding-top: 10px;
        }

        .container-radio {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          @media (max-width: 1920px) {
            margin-top: 12px;
          }
        }
      }

      ////////////////////////
      // Habiter / Investir //
      ////////////////////////

      .habinv {

        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start !important;
        margin-bottom: 5px;

        .label-habinv {
          margin-bottom: 8px;
        }

        .habinv-container {
          border: 1px solid $colPuceSwitchCiv;
          width: 170px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 14px 0px;


          label {
            width: 49%;
            text-align: center;
          }
        }

        label {
          @include TypoOutBold();
          font-size: 13px;
        }
        .label-radio {
          cursor: pointer;
        
          & + .label-radio {
            margin-left: 0px;
          }
        
          .indicator {
            @include TypoLexLi();
            color: #000000;
            position: relative;
            font-size: 20px;
            padding: 7px 11px;
            padding-left: 11px !important;
            text-align: center;
          }
        
          .indicator:before {
            width: 20px;
            height: 20px;
            border: 1px solid $colBorderRadioBasicCiv;
            background-color: $colbgRadioBasicCiv;
            border-radius: 100%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 0;
            display: none;
          }
        
          .indicator:after {
            width: 0px;
            height: 0px;
            background: $colPuceRadioBasicCiv;
            border-radius: 100%;
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            left: 4px;
            display: none;
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            transform: scale(0);
            -webkit-transition-duration: 0.3s;
            -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
          }

          input:checked + .indicator {
            background: $colPuceRadioBasicCiv;
            color: #ffffff;
          }
        
          input {
            display: none;
          }
        
          input:checked + .indicator:after {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
          }
        }
        .fst-lab {
          margin-right: 30px;
        
          @media (max-width: 575px) {
            margin-right: 20px;
          }
        }
      }

      .civ {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5px;
        margin-bottom: 20px;
      }

      .button-vald {
        width: 100%;
      }

      .form-mentions {
        @include TypoLexLi();
        font-size: 14px;
        color: #000000;
        margin-bottom: 15px;
        //word-spacing: -2px;

        @media (max-width: 768px) {
          margin-bottom: 0;
          margin-top: 20px;
        }

        span {
          @include TypoLexLi();
          font-size: 14px;
          word-spacing: 0;
          color: #000000;
        }

        a {
          display: contents;
          color: $colTypoMentionForm;
          text-decoration: underline !important;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.deskHide {
  display: none;
}
.mention-log {
  width: 591px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  .mentions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .separation {
      color: #ffffff;
      margin: 0 5px;
    }

    a,
    button {
      border: none;
      background-color: transparent;
      @include TypoOutBold();
      text-decoration: underline;
      color: #ffffff;
      font-size: 11px;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
  .logos {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;

    .covivio {
      width: 50px;
      margin-right: 15px;
    }

    .bordeaux {
      width: 50px;
    }
  }
}
.mentions-mob {
  display: none;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  margin-top: 25px;
  margin-bottom: 50px;

  a,
  button {
    border: none;
    background-color: transparent;
    @include TypoOutBold();
    color: #202e44;
    font-size: 11px;
  }
  .separation {
    color: #202e44;
    margin: 0 5px;
  }

  a,
  button {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}