.footer_yama {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1920px) {
    height: 150px;
  }
  @media (max-width: 1280px) {
    height: auto;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    width: 35%;
    height: 100%;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 1280px) {
      width: 100%;
      height: 120px;
    }

    .img {
      width: 140px;
    }
  }

  .container_links {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 8px;

    @media (max-width: 1280px) {
      width: 100%;
      height: 120px;
    }
    @media (max-width: 768px) {
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      padding: 30px 0;
    }

    a {
      padding: 10px 30px;
      text-decoration: none;
      margin-right: 50px;

      @media (max-width: 1920px) {
        padding: 10px 20px;
        margin-right: 20px;
      }
      @media (max-width: 1280px) {
        padding: 10px 10px;
        margin-right: 10px;
      }
      @media (max-width: 768px) {
        margin-right: 0;
      }

      .link {
        position: relative;
        overflow: hidden;
        transition: all 0.3s ease-in-out;

        p {
          @include TypoLexSemi();
          font-size: 18px;
          color: #000000;
          margin-bottom: 8px;

          @media (max-width: 1920px) {
            font-size: 16px;
          }
        }
        .line {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #000000;
          transform: translateX(-105%);
          transition: all 0.3s ease-in-out;
        }
      }
      
      &:hover {
        .link {

          .line {
            transform: translateX(0);
          }
        }
      }
    }
    #red_footer {
      width: 55px;
      height: 55px;
      background-color: #841820;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      cursor: pointer;

      @media (max-width: 768px) {
        margin-bottom: 0;
        margin-top: 10px;
      }
      .img {
        width: 24px;
      }
    }
  }
}