//////////
// MENU //
//////////

.menuHam {
  position: absolute;
  top: 0;
  left: 0;
  height: 140px;
  background-color: transparent;
  z-index: 5000;
  color: #ffffff;
  display: none;
  width: 100%;
  
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .empty {
    width: 85px;

    @media (max-width: 768px) {
      width: 70px;
    }
  }

  .mobMenu {
    display: flex;
    margin-right: 15px;
    margin-top: 15px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-top: 0;
    }
  }

  .logo {
    width: 150px;
    margin-top: 60px;

    @media (max-width: 1280px) {
      width: 150px;
    }
    @media (max-width: 768px) {
      width: 95px;
      margin-top: 15px;
    }
  }
}

/////////////////
// MENU MOBILE //
/////////////////

.mobNav {
  display: none;


  @media (max-width: 1280px) {
    //display: flex;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100vh - 54px);
    background-color: transparent;
    //height: calc(100vh - 134px);
    z-index: 2000;
    margin-top: 60px;
    margin-right: 0;
    background-color: #000000;
    display: none;

    ol {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 86px;

      li {
        padding: 0;
        margin: 0;
        display: block;
        text-align: center;
        margin-bottom: 40px;

        &:hover {
          cursor: pointer;
        }

        &:last-child {
          border: none;
          margin-bottom: 0;
        }

        a {
          text-decoration: none;
          text-transform: uppercase;
          color: #ffffff;
          outline: none;
          position: relative;
          letter-spacing: 0px;
          @include TypoPopMed();
          font-size: 18px;
          padding: 15px;

          @media (max-width: 768px) {
            font-size: 16px;
          }
        }
      }

      .btnContact {
        color: #ffffff;
      }
    }
  }
  @media (max-width: 768px) {
    height: calc(100vh - 39px);
    margin-top: 39px;
  }
}

.mobNavOpen {
  display: flex;
  
    @media (min-width: 767px) {
      width: 100%;
      opacity: 1;
      transition-property: width, opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease-in-out;
    }
    @media (max-width: 768px) {
      opacity: 1;
    }
}
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

 .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    opacity: 0.7;
  }
}
.hamburger.is-active {
  &:hover {
    opacity: 0.7;
  }
  .hamburger-inner {
    background-color: #fff;
    &::before {
      background-color: #fff;
    }
    &::after {
      background-color: #fff;
    }
  }
}
.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}
.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  width: 40px;
  height: 4px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
  &::before {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
    top: -10px;
  }
  &::after {
    width: 40px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    content: "";
    display: block;
    bottom: -10px;
  }
}
.hamburger--3dx {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dx.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateY(180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--3dx-r {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dx-r.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateY(-180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--3dy {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dy.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateX(-180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--3dy-r {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dy-r.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateX(180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--3dxy {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dxy.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateX(180deg) rotateY(180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--3dxy-r {
  .hamburger-box {
    perspective: 80px;
  }
  .hamburger-inner {
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
      background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    &::before {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &::after {
      transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
.hamburger--3dxy-r.is-active {
  .hamburger-inner {
    background-color: transparent !important;
    transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
    &::before {
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--arrow.is-active {
  .hamburger-inner {
    &::before {
      transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
    &::after {
      transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
    }
  }
}
.hamburger--arrow-r.is-active {
  .hamburger-inner {
    &::before {
      transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
    }
    &::after {
      transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
  }
}
.hamburger--arrowalt {
  .hamburger-inner {
    &::before {
      transition: top 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &::after {
      transition: bottom 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}
.hamburger--arrowalt.is-active {
  .hamburger-inner {
    &::before {
      top: 0;
      transform: translate3d(-8px, -10px, 0) rotate(-45deg) scale(0.7, 1);
      transition: top 0.1s ease,
        transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
    &::after {
      bottom: 0;
      transform: translate3d(-8px, 10px, 0) rotate(45deg) scale(0.7, 1);
      transition: bottom 0.1s ease,
        transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  }
}
.hamburger--arrowalt-r {
  .hamburger-inner {
    &::before {
      transition: top 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &::after {
      transition: bottom 0.1s 0.1s ease,
        transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
}
.hamburger--arrowalt-r.is-active {
  .hamburger-inner {
    &::before {
      top: 0;
      transform: translate3d(8px, -10px, 0) rotate(45deg) scale(0.7, 1);
      transition: top 0.1s ease,
        transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
    &::after {
      bottom: 0;
      transform: translate3d(8px, 10px, 0) rotate(-45deg) scale(0.7, 1);
      transition: bottom 0.1s ease,
        transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    }
  }
}
.hamburger--arrowturn.is-active {
  .hamburger-inner {
    transform: rotate(-180deg);
    &::before {
      transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
    }
    &::after {
      transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
  }
}
.hamburger--arrowturn-r.is-active {
  .hamburger-inner {
    transform: rotate(-180deg);
    &::before {
      transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
    }
    &::after {
      transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
    }
  }
}
.hamburger--boring {
  .hamburger-inner {
    transition-property: none;
    &::before {
      transition-property: none;
    }
    &::after {
      transition-property: none;
    }
  }
}
.hamburger--boring.is-active {
  .hamburger-inner {
    transform: rotate(45deg);
    &::before {
      top: 0;
      opacity: 0;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}
.hamburger--collapse {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }
    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--collapse.is-active {
  .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
    }
    &::before {
      top: 0;
      transform: rotate(-90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--collapse-r {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0.1s linear;
    }
    &::before {
      transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--collapse-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0.1s 0.22s linear;
    }
    &::before {
      top: 0;
      transform: rotate(90deg);
      transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--elastic {
  .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &::before {
      top: 10px;
      transition: opacity 0.125s 0.275s ease;
    }
    &::after {
      top: 20px;
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
}
.hamburger--elastic.is-active {
  .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
    &::before {
      transition-delay: 0s;
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -20px, 0) rotate(-270deg);
      transition-delay: 0.075s;
    }
  }
}
.hamburger--elastic-r {
  .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &::before {
      top: 10px;
      transition: opacity 0.125s 0.275s ease;
    }
    &::after {
      top: 20px;
      transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  }
}
.hamburger--elastic-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(-135deg);
    transition-delay: 0.075s;
    &::before {
      transition-delay: 0s;
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -20px, 0) rotate(270deg);
      transition-delay: 0.075s;
    }
  }
}
.hamburger--emphatic {
  overflow: hidden;
  .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
    &::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
        top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
    }
    &::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
        top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
    }
  }
}
.hamburger--emphatic.is-active {
  .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
    &::before {
      left: -80px;
      top: -80px;
      transform: translate3d(80px, 80px, 0) rotate(45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear,
        transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::after {
      right: -80px;
      top: -80px;
      transform: translate3d(-80px, 80px, 0) rotate(-45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear,
        transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
.hamburger--emphatic-r {
  overflow: hidden;
  .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
    &::before {
      left: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
        top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
    }
    &::after {
      top: 10px;
      right: 0;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
        top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
    }
  }
}
.hamburger--emphatic-r.is-active {
  .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
    &::before {
      left: -80px;
      top: 80px;
      transform: translate3d(80px, -80px, 0) rotate(-45deg);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear,
        transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::after {
      right: -80px;
      top: 80px;
      transform: translate3d(-80px, -80px, 0) rotate(45deg);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear,
        transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
.hamburger--minus {
  .hamburger-inner {
    &::before {
      transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out,
        opacity 0s linear;
    }
    &::after {
      transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out,
        opacity 0s linear;
    }
  }
}
.hamburger--minus.is-active {
  .hamburger-inner {
    &::before {
      opacity: 0;
      transition: bottom 0.08s ease-out, top 0.08s ease-out,
        opacity 0s 0.08s linear;
      top: 0;
    }
    &::after {
      opacity: 0;
      transition: bottom 0.08s ease-out, top 0.08s ease-out,
        opacity 0s 0.08s linear;
      bottom: 0;
    }
  }
}
.hamburger--slider {
  .hamburger-inner {
    top: 2px;
    &::before {
      top: 10px;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }
    &::after {
      top: 20px;
    }
  }
}
.hamburger--slider.is-active {
  .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(45deg);
    &::before {
      transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -20px, 0) rotate(-90deg);
    }
  }
}
.hamburger--slider-r {
  .hamburger-inner {
    top: 2px;
    &::before {
      top: 10px;
      transition-property: transform, opacity;
      transition-timing-function: ease;
      transition-duration: 0.15s;
    }
    &::after {
      top: 20px;
    }
  }
}
.hamburger--slider-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(-45deg);
    &::before {
      transform: rotate(45deg) translate3d(5.71429px, -6px, 0);
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -20px, 0) rotate(90deg);
    }
  }
}
.hamburger--spin {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }
    &::after {
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--spin.is-active {
  .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--spin-r {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::before {
      transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    }
    &::after {
      transition: bottom 0.1s 0.25s ease-in,
        transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--spin-r.is-active {
  .hamburger-inner {
    transform: rotate(-225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    }
    &::after {
      bottom: 0;
      transform: rotate(90deg);
      transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--spring {
  .hamburger-inner {
    top: 2px;
    transition: background-color 0s 0.13s linear;
    &::before {
      top: 10px;
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      top: 20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--spring.is-active {
  .hamburger-inner {
    transition-delay: 0.22s;
    background-color: transparent !important;
    &::before {
      top: 0;
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(45deg);
    }
    &::after {
      top: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 10px, 0) rotate(-45deg);
    }
  }
}
.hamburger--spring-r {
  .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::after {
      top: -20px;
      transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        opacity 0s linear;
    }
    &::before {
      transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
        transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--spring-r.is-active {
  .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::after {
      top: 0;
      opacity: 0;
      transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        opacity 0s 0.22s linear;
    }
    &::before {
      top: 0;
      transform: rotate(90deg);
      transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
        transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--stand {
  .hamburger-inner {
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      background-color 0s 0.075s linear;
    &::before {
      transition: top 0.075s 0.075s ease-in,
        transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      transition: bottom 0.075s 0.075s ease-in,
        transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--stand.is-active {
  .hamburger-inner {
    transform: rotate(90deg);
    background-color: transparent !important;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0s 0.15s linear;
    &::before {
      top: 0;
      transform: rotate(-45deg);
      transition: top 0.075s 0.1s ease-out,
        transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::after {
      bottom: 0;
      transform: rotate(45deg);
      transition: bottom 0.075s 0.1s ease-out,
        transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--stand-r {
  .hamburger-inner {
    transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19),
      background-color 0s 0.075s linear;
    &::before {
      transition: top 0.075s 0.075s ease-in,
        transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    &::after {
      transition: bottom 0.075s 0.075s ease-in,
        transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--stand-r.is-active {
  .hamburger-inner {
    transform: rotate(-90deg);
    background-color: transparent !important;
    transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1),
      background-color 0s 0.15s linear;
    &::before {
      top: 0;
      transform: rotate(-45deg);
      transition: top 0.075s 0.1s ease-out,
        transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &::after {
      bottom: 0;
      transform: rotate(45deg);
      transition: bottom 0.075s 0.1s ease-out,
        transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }
    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }
}
.hamburger--squeeze.is-active {
  .hamburger-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
.hamburger--vortex {
  .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &::before {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear;
      transition-property: top, opacity;
    }
    &::after {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear;
      transition-property: bottom, transform;
    }
  }
}
.hamburger--vortex.is-active {
  .hamburger-inner {
    transform: rotate(765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &::before {
      transition-delay: 0s;
      top: 0;
      opacity: 0;
    }
    &::after {
      transition-delay: 0s;
      bottom: 0;
      transform: rotate(90deg);
    }
  }
}
.hamburger--vortex-r {
  .hamburger-inner {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &::before {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear;
      transition-property: top, opacity;
    }
    &::after {
      transition-duration: 0s;
      transition-delay: 0.1s;
      transition-timing-function: linear;
      transition-property: bottom, transform;
    }
  }
}
.hamburger--vortex-r.is-active {
  .hamburger-inner {
    transform: rotate(-765deg);
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    &::before {
      transition-delay: 0s;
      top: 0;
      opacity: 0;
    }
    &::after {
      transition-delay: 0s;
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}
.navEquipe {
  background-color: #841820;
}