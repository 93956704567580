.btn-circle-blanc {
  position: relative;
  background-color: #ffffff;
  outline: none;
  border: none;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;

  p {
    color: #000000;
    text-transform: uppercase;
    @include TypoLexSemi();
    font-size: 35px;
    text-align: center;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;

    @media (max-width: 1920px) {
      font-size: 28px;
    }
  }
  .img {
    width: 26px;
    margin-left: 15px;

    @media (max-width: 1920px) {
      width: 19px;
      margin-left: 5px;
    }
  }
  .line {
    position: absolute;
    bottom: 10px;
    width: 100%;
    height: 2px;
    background-color: #000000;
    transform: translateX(-105%);
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }

  &:hover {

    p {
      color: #841820;
    }
    .line {
      transform: translateX(0);
    }
    
  }
}
#DivLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: none;
}
.btn_loader {
  position: relative;
  outline: none;
  border: none;
  width: 315px;
  height: 60px;
  border: 2px solid #000000;
  background-color: #ffffff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;

  .img {
      width: 40px !important;
      margin: 0 !important;
  }
  img {
      width: 100%;
      height: auto;
  }
}