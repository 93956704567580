.visuel-slider {
  width: 100%;
  position: relative;
  display: none;
  padding-bottom: 90px;

  @media (max-width: 1280px) {
    display: block;
  }

  .glide {
    width: 100%;
    position: relative;

    .glide__track {
      width: 100%;
      overflow: hidden;

      ul {
        display: flex;
        flex-direction: row;
        li {

          .image_Slider {
            width: 100%;
            height: 405px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            margin-top: 20px;

            .title {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-start;

              .chiffre {
                padding: 5px;
                background-color: #841820;
                margin-right: 10px;

                p {
                  @include TypoGunSemi();
                  font-weight: 800;
                  font-size: 32px;
                  color: #ffffff;

                  @media (max-width: 768px) {
                    font-size: 24px; 
                  }
                }
              }
              .city {
                @include TypoLexSemi();
                font-size: 32px;
                color: #ffffff;

                @media (max-width: 768px) {
                  font-size: 24px; 
                }
              }
            }
            .text_descr {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-top: 30px;

              .top {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                h4 {
                  @include TypoLexSemi();
                  font-size: 16px;
                  color: #ffffff;
                  margin-bottom: 10px;
                  text-transform: uppercase;

                  span {
                    font-size: 22px;
                    padding-bottom: 12px;
                    display: block;
                  }

                }
                h3 {
                  @include TypoLexSemi();
                  font-size: 16px;
                  color: #ffffff;

                  sup {
                    font-size: 10px;
                  }
                }
                p {
                  @include TypoLexLi();
                  font-size: 14px;
                  color: #ffffff;
                  line-height: 120%;
                  margin-top: 10px;
                }
              }
              .bot {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-top: 25px;

                p {
                  @include TypoLexLi();
                  font-size: 16px;
                  color: #ffffff;
                  margin-bottom: 5px;
                  line-height: 140%;

                  span {
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }

    .btnGlide {
      position: absolute;
      height: 100%;
      border: none;
      width: 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0;

      @media (max-width: 767px) {
        width: 30px;
      }

      .containerBtn {
        background: linear-gradient(
          transparent,
          rgba(0, 0, 0, 0.25),
          transparent
        );
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          height: 100px;
        }
        img {
          width: 20px;
          height: auto;
          @media (max-width: 767px) {
            width: 15px;
          }
        }
      }
    }
    .glideLeft {
      left: 0;
      top: 0;
    }
    .glideRight {
      right: 0;
      top: 0;
    }
  }
  /*.glide-mob {
    width: 100%;
    height: 100%;
    position: relative;

    .glide__track {
      width: 100%;
      height: 100%;

      ul {
        width: 100%;
        height: 100%;

        li {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .slide1 {
          background-image: url("/images/galerie-desktop/slider1.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider1.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider1.png");
          }
        }
        .slide2 {
          background-image: url("/images/galerie-desktop/slider2.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider2.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider2.png");
          }
        }
        .slide3 {
          background-image: url("/images/galerie-desktop/slider3.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider3.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider3.png");
          }
        }
        .slide4 {
          background-image: url("/images/galerie-desktop/slider4.png");
          @media (max-width: 1439px) {
            background-image: url("/images/galerie-tab/slider4.png");
          }
          @media (max-width: 767px) {
            background-image: url("/images/galerie-mob/slider4.png");
          }
        }
      }
    }

    .btnGlide {
      position: absolute;
      height: 100%;
      border: none;
      width: 50px;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding: 0;

      @media (max-width: 767px) {
        width: 30px;
      }

      .containerBtn {
        background: linear-gradient(
          transparent,
          rgba(0, 0, 0, 0.25),
          transparent
        );
        height: 300px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media (max-width: 767px) {
          height: 100px;
        }
        img {
          width: 20px;
          height: auto;
          @media (max-width: 767px) {
            width: 15px;
          }
        }
      }
    }
    .glideLeft {
      left: 0;
      top: 0;
    }
    .glideRight {
      right: 0;
      top: 0;
    }
  }*/
  .glide__bullets {
    position: absolute;
    bottom: 10px;
    z-index: 999;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .glide__bullet {
      background-color: red;
      border: none;
      height: 10px;
      width: 14px;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.slider-desk {
  @media (max-width: 1439px) {
    display: none;
  }
}
