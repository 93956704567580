#optin, #civ {
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  @media (max-width: 1920px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    align-items: center;
  }

  .indicator {
    width: 100%;
    @include TypoLexLi();
    color: #000000;
    position: relative;
    font-size: 20px;
    line-height: 140%;

    @media (max-width: 1920px) {
      font-size: 16px;
    }
    @media (max-width: 768px) {
      text-align: center;
    }

    a {
      display: contents;
      color: $typoForm;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .error {
    color: red;
    font-size: 12px;
    padding-top: 0px;
    position: absolute;
    left: 0;
    top: 30px;
    width: 100%;
    @include TypoLexLi();
  }

  .container-radio {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    :first-child {
      margin-right: 45px;

      @media (max-width: 1920px) {
        margin-right: 40px;
      }
    }

    .radio {
      display: block;
      position: relative;
      padding-left: 40px;
      cursor: pointer;
      font-size: 20px;
      color: #000000;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      line-height: 140%;

      @media (max-width: 1920px) {
        font-size: 16px;
      }
      @media (max-width: 768px) {
        padding-left: 35px;
      }

      :first-child {
        margin-right: 0;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark-radio {
        position: absolute;
        left: 0;
        height: 25px;
        width: 25px;
        top: -1px;
        background-color: #ffffff;
        border: 1px solid #000000;
        border-radius: 50%;

        /*@media (max-width: 768px) {
          top: -1px;
        }*/
      }

      input:checked ~ .checkmark-radio {
        background-color: #ffffff;
      }

      .checkmark-radio:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked ~ .checkmark-radio:after {
        display: block;
        background-color: #000000;
      }

      .checkmark-radio:after {
        top: 4px;
        left: 4px;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        background: #000000;

        /*
        @media (max-width: 1024px) {
          top: 4px;
          left: 4px;
          height: 10px;
          width: 10px;
        }*/
      }
    }
  }
}
#civ {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  p {
    @include TypoPopLi();
    font-size: 16px;
    color: #ffffff;
    margin-right: 60px;

    @media (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    align-items: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-radio {
      margin: 0;
  }
}